import axios from 'axios'
import * as VMasker from "vanilla-masker"
import Cookies from 'js-cookie';

export default class WhatsAppContatoLateral {
    constructor() {

        this.section = document.querySelector('#modalWhatsApp')
        this.sectionEmpreendimento = document.querySelector('.page-empreendimento')

        this.form = document.getElementById('formWhatsAppLateral')
        this.btnSubmitForm = this.form.querySelector('#btnEnviarContato')

        // console.log(this.btnSubmitForm)
        
        this.fieldName = this.form.querySelector('#nome')
        this.fieldEmail = this.form.querySelector('#email')
        this.fieldPhone = this.form.querySelector('#telefone')
        this.fieldState = this.section.querySelector('#dropdown-get-state-whats');

        this.stateLocal = this.form.querySelector('#idempreendimento')
        
        this.errorForm = this.section.querySelector('.erroFormulario');
        this.sucessoForm = this.section.querySelector('.sucessoFormulario');

        this.telMask = ['(99)99999-9999'];

        this.loading = document.querySelector('.modalLoading')

        this.events()

        this.url = window.location.origin;

        //Select Estado
        if(!this.sectionEmpreendimento) {
            if(this.fieldState) {
                const listEmpreendimentos = this.fieldState.querySelectorAll('.dropdown-list a');
                const spanState = this.fieldState.querySelector('#textState');
        
                this.fieldState.addEventListener('click', function() {
                    this.classList.toggle('is-active');
                });
        
                listEmpreendimentos.forEach((element) => {
                    element.addEventListener('click', (evt) => {
                        const valueData = evt.currentTarget.textContent;
                        spanState.innerHTML = valueData
                        console.log(evt.currentTarget.dataset.value)
                        // document.getElementById('cidade').value = evt.currentTarget.textContent
                        this.section.querySelector('#idempreendimento').value = evt.currentTarget.dataset.value

                    })
                })
            }
        }

    }

    serializeJSON(form) {
        // Create a new FormData object
        const formData = new FormData(form);
        // const csrftoken = this.getCookie('csrftoken');

        // Create an object to hold the name/value pairs
        const pairs = {};

        // Add each name/value pair to the object
        for (const [name, value] of formData) {
            if (name.endsWith('[]')) {
                const finalName = name.slice(0, -2);

                if (!pairs[finalName]) {
                    pairs[finalName] = [];
                }

                pairs[finalName].push(value);
            } else {
                pairs[name] = value;
            }
        }

        // Return the JSON string
        return JSON.stringify(pairs, null, 2);
    }
    
    sendForm() {

        this.loading.classList.add('active')
      
        const dataForm = new FormData(this.form); // console.log(dataForm)
        // console.log(dataForm)

        const messageFormContent = `
            utm_source: ${Cookies.get('cflutmsource')}
            utm_medium: ${Cookies.get('cflutmmedium')}
            utm_campaign: ${Cookies.get('cflutmcampaign')}
        `;

        axios({
            method: "post",
            url: url + "/email/form_whatsapp_lead_lateral.php",
            data: dataForm,
            headers: {"Content-Type": "multipart/form-data"}
        })
            .then((dataReturn) => {

                // console.log('Retorno: ', dataReturn);
                let dadosEmail = parseInt(dataReturn.data);

                if(dadosEmail === 1) {

                    this.form.reset();
                    this.sucessoForm.style.display = 'block';
                    this.loading.classList.remove('active')

                }

            })
            .catch((data) => {
                console.warn('ERRO')
                console.log(data)
                this.loading.classList.remove('active')
            })
            
    }

    maskfields(masks, max, event) {
        var c = event.target;
        var v = c.value.replace(/\D/g, '');
        var m = c.value.length > max ? 1 : 0;
        VMasker(c).unMask();
        VMasker(c).maskPattern(masks[m]);
        c.value = VMasker.toPattern(v, masks[m]);
    }
    
    validateField() {
        
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        let error = 0

        if(!this.sectionEmpreendimento) {
            if(+this.stateLocal.value === 0) {
                error++
                console.log('Estado', +this.stateLocal.value)
                this.fieldState.classList.add('error-field')
            } else {
                this.fieldState.classList.remove('error-field')
            }
        }

        if(this.fieldName.value.length === 0) {
            error++
            this.fieldName.parentElement.classList.add('error-field')
        } else {
            this.fieldName.parentElement.classList.remove('error-field')
        }
        
        if(!this.fieldEmail.value.match(mailformat)) {
            error++
            this.fieldEmail.parentElement.classList.add('error-field')
            
        } else {
            this.fieldEmail.parentElement.classList.remove('error-field')
        }

        if(this.fieldPhone.value.length === 0) {
            error++
            this.fieldPhone.parentElement.classList.add('error-field')
        } else {
            this.fieldPhone.parentElement.classList.remove('error-field')
        }

        if(error === 0) {
            this.sendForm()
            this.errorForm.style.display = 'none';
        } else {
            this.errorForm.style.display = 'block';
        }

    }

    events() {

        VMasker(this.fieldPhone).maskPattern(this.telMask[0]);
        this.fieldPhone.addEventListener('input', this.maskfields.bind(undefined, this.telMask, 14), false);

        this.btnSubmitForm.addEventListener('click', this.validateField.bind(this))
        
    }

}